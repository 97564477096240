<!-- eslint-disable vuejs-accessibility/tabindex-no-positive -->
<template>
  <nav
    class="fixed -top-full left-[10%] z-[1000] transition-all before:fixed before:-top-full before:left-0 before:z-50 before:h-screen before:w-screen before:bg-transparent before:backdrop-blur-0 before:transition-[backdrop-filter,_background-color] before:duration-500 before:focus-within:bg-black/5 md:focus-within:-top-1 md:before:focus-within:-top-0 md:before:focus-within:backdrop-blur-sm"
    aria-label="Accessibility navigation"
  >
    <ul
      class="fixed z-[1000] space-y-2 rounded border-2 border-gold-300 bg-white px-8 pb-5 pt-6 shadow-lg"
    >
      <li>
        <button
          tabindex="1"
          class="focus-default px-2 text-lg hover:underline"
          type="button"
          :aria-label="$t('navigate.toNav')"
          @click="jumptoid('jump-navigation')"
        >
          <span>{{ $t('navigate.toNav') }}</span>
        </button>
      </li>
      <li>
        <button
          tabindex="1"
          class="focus-default px-2 text-lg hover:underline"
          type="button"
          :aria-label="$t('navigate.toPage')"
          @click="jumptoid('jump-contend-start')"
        >
          <span>{{ $t('navigate.toPage') }}</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
useTabHelper()

function jumptoid(id) {
  const el = document.getElementById(id)

  if (el?.getElementsByTagName('a')[0]) {
    el?.getElementsByTagName('a')[0]?.focus()
  } else {
    el?.focus()
  }

  el?.scrollIntoView({ behavior: 'smooth' })
}
</script>
